import { useState } from 'react';

const simpleNotifications = (title, options) => {
  new Notification(title, options);
};

const workerNotifications = (title, options) => {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.showNotification(title, options);
    })
    .catch((error) => {
      console.log('showNotification error', error);
    });
};

const useNotification = () => {
  const [permission, setPermission] = useState('default');

  const requestNotificationPermission = () => {
    if (permission === 'granted') {
      return;
    }

    Notification.requestPermission()
      .then((newPermission) => {
        setPermission(newPermission);
      })
      .catch((error) => {
        console.error('Error al solicitar permisos de notificación:', error);
      });
  };

  const serviceWorkerSupported = () => 'serviceWorker' in navigator;

  const isNotificationSupported = () => 'Notification' in window;

  const showNotification = (title, options) => {
    if (isNotificationSupported()) {
      simpleNotifications(title, options);
    } else if (serviceWorkerSupported()) {
      workerNotifications(title, options);
    }
  };

  return { permission, requestNotificationPermission, showNotification };
};

export default useNotification;
